import React, { Component } from 'react'

export default class login extends Component {
    render() {
        return (
                <div className="w-screen h-screen flex justify-center items-center bg-gray-100">
                    <form className="p-10 bg-white rounded flex justify-center items-center flex-col shadow-md">
                        
                        <p className="mb-5 text-3xl uppercase text-gray-600">Login</p>
                        <input type="email" name="email" className="mb-5 p-3 w-80 focus:border-violet-400 rounded border-2 outline-none" autocomplete="off" placeholder="Email" required />
                        <input type="password" name="password" className="mb-5 p-3 w-80 focus:border-violet-400 rounded border-2 outline-none" autocomplete="off" placeholder="Password" required />
                        <button className="bg-violet-400 hover:bg-violet-700 text-white font-bold p-2 rounded w-80" id="login" type="submit"><span>Login</span></button>
                    </form>
                </div>

        )
    }
}
