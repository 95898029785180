import Loader from "react-loader-spinner";

function Spinner() {
  return (
    <div>
    <div className="flex h-screen w-screen justify-center items-center"><Loader
      type="Rings"
      visible ={true}
      color="#00BFFF"
      height={100}
      width={100} />
      </div>
    </div>
  );
}

export default Spinner;
